import {
    DefaultButton,
    Dialog,
    Dropdown,
    IDropdownOption,
    PrimaryButton,
    ProgressIndicator,
    Stack,
    StackItem,
} from "@fluentui/react";
import {
    Adb,
    AdbDaemonDevice,
    AdbDaemonTransport,
    AdbPacketData,
    AdbPacketInit,
} from "@yume-chan/adb";
import AdbWebCredentialStore from "@yume-chan/adb-credential-web";
import AdbDaemonDirectSocketsDevice from "@yume-chan/adb-daemon-direct-sockets";
import AdbDaemonWebSocketDevice from "@yume-chan/adb-daemon-ws";
import {
    Consumable,
    InspectStream,
    ReadableStream,
    WritableStream,
    pipeFrom,
} from "@yume-chan/stream-extra";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GLOBAL_STATE } from "../state";
import { CommonStackTokens, Icons } from "../utils";
import { useRouter } from 'next/router';

const DropdownStyles = { dropdown: { width: "100%" } };

const CredentialStore = new AdbWebCredentialStore();

function ConnectCore(): JSX.Element | null {
    const router = useRouter();
    const [selected, setSelected] = useState<AdbDaemonDevice | undefined>();
    const [connecting, setConnecting] = useState(false);

    const [webSocketDeviceList, setWebSocketDeviceList] = useState<AdbDaemonWebSocketDevice[]>([]);
    useEffect(() => {
        const key = localStorage.getItem("accept_key");
        console.log('key: ' + key);

        const device_id = localStorage.getItem("device_id");
        console.log('device_id: ' + device_id);
        if (!key) {
            const { check_key } = router.query;
            if (check_key) {
                console.log('check key: ' + check_key);
                return;
            } else {
                // console.log("No key found in localStorage");
                localStorage.setItem('accept_key', 'null');
                window.location.href = "https://dev.kdata.vn/user/cloud-phone/console";
                return;
            }
        }
        const checkKeyValidity = async () => {
            try {
                console.log('call checkKeyValidity');

                const response = await fetch(`https://dev.kdata.vn/api/v1.0/cloud-phone/list-service`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + key,
                    }, body: JSON.stringify({
                        device_id: device_id,
                    }),
                });
                const statusCode = response.status; // Lấy status của response
                if (statusCode == 200) {
                    const result = await response.json();
                    console.log(result)
                    if (result.status == 'SUCCESS') {
                        globalThis.localStorage.setItem(
                            "ws-backend-list",
                            JSON.stringify(result.data.map((x: { extra_server: string; domain: string }) => ({
                                address: x.extra_server,
                                name: x.domain,
                            }))),
                        );
                        const savedList = localStorage.getItem("ws-backend-list");
                        if (!savedList) {
                            return;
                        }
                        const parsed = JSON.parse(savedList) as { address: string; name: string }[];
                        const selectedDevice = new AdbDaemonWebSocketDevice(parsed[0].address, parsed[0].name);
                        setSelected(selectedDevice);
                        await connectDevice(selectedDevice);
                    }
                    else {
                        GLOBAL_STATE.showErrorAlert(result.status + ': ' + result.message);
                        return;
                    }
                } else if (statusCode == 401) {
                    GLOBAL_STATE.showErrorAlert("Token của bạn đã hết hạn hoặc không hợp lệ. Nhấn 'OK' để lấy lại mã");
                    window.location.href = "https://dev.kdata.vn/user/cloud-phone/console";
                    return;
                } else {
                    console.log("Lỗi khi gọi API");
                    const result = await response.json();
                    console.log(result)
                    window.location.href = "https://dev.kdata.vn/user/cloud-phone/console";
                    return;
                }
            } catch (error) {
                console.error("Đã xảy ra lỗi:", error);
                return;
            }
        };
        checkKeyValidity();
    }, []);

    const connectDevice = useCallback(async (selectedDevice: AdbDaemonDevice) => {
        if (!selectedDevice) {
            return;
        }
        try {
            const key = localStorage.getItem("accept_key");
            if (!key) {
                return;
            }
            const response = await fetch(`https://dev.kdata.vn/api/v1.0/cloud-phone/check-connect`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + key,
                },
                body: JSON.stringify(selectedDevice),
            });

            const statusCode = response.status;
            if (statusCode == 200) {
                const result = await response.json();
                if (result.status == 'SUCCESS') {
                    setConnecting(true);

                    let readable: ReadableStream<AdbPacketData>;
                    let writable: WritableStream<Consumable<AdbPacketInit>>;
                    try {
                        const streams = await selectedDevice.connect();

                        readable = streams.readable.pipeThrough(
                            new InspectStream((packet) => {
                                GLOBAL_STATE.appendLog("in", packet);
                            }),
                        );

                        writable = pipeFrom(
                            streams.writable,
                            new InspectStream((packet: Consumable<AdbPacketInit>) => {
                                GLOBAL_STATE.appendLog("out", packet.value);
                            }),
                        );
                    } catch (e: any) {
                        GLOBAL_STATE.showErrorDialog(e);
                        setConnecting(false);
                        return;
                    }

                    async function dispose() {
                        try {
                            readable.cancel();
                        } catch {
                        }
                        try {
                            await writable.close();
                        } catch {
                        }
                        GLOBAL_STATE.setDevice(undefined, undefined);
                    }

                    try {
                        const device = new Adb(
                            await AdbDaemonTransport.authenticate({
                                serial: selectedDevice.serial,
                                connection: { readable, writable },
                                credentialStore: CredentialStore,
                            }),
                        );

                        device.disconnected.then(
                            async () => {
                                await dispose();
                            },
                            async (e) => {
                                GLOBAL_STATE.showErrorDialog(e);
                                await dispose();
                            },
                        );

                        GLOBAL_STATE.setDevice(selectedDevice, device);
                    } catch (e: any) {
                        GLOBAL_STATE.showErrorDialog(e);
                        await dispose();
                    } finally {
                        setConnecting(false);
                    }
                } else {
                    console.log(result.status);
                    return;
                }
            } else if (statusCode == 401) {
                GLOBAL_STATE.showErrorAlert("Token của bạn đã hết hạn hoặc không hợp lệ. Nhấn 'OK' để lấy lại mã");
                window.location.href = "https://dev.kdata.vn/user/cloud-phone/console";
                return;
            } else {
                console.log("Lỗi khi gọi API");
                const result = await response.json();
                console.log(result);
                return;
            }
        } catch (error) {
            console.error("Đã xảy ra lỗi:", error);
            return;
        }
    }, []);

    const [tcpDeviceList, setTcpDeviceList] = useState<AdbDaemonDirectSocketsDevice[]>([]);
    useEffect(() => {
        if (!AdbDaemonDirectSocketsDevice.isSupported()) {
            return;
        }

        const savedList = localStorage.getItem("tcp-backend-list");
        if (!savedList) {
            return;
        }

        const parsed = JSON.parse(savedList) as {
            address: string;
            port: number;
        }[];
        setTcpDeviceList(
            parsed.map(
                (x) => new AdbDaemonDirectSocketsDevice(x.address, x.port),
            ),
        );
    }, []);
    const deviceList = useMemo(
        () =>
            ([] as AdbDaemonDevice[]).concat(
                webSocketDeviceList,
                tcpDeviceList,
            ),
        [webSocketDeviceList, tcpDeviceList],
    );
    useEffect(() => {
        setSelected((old) => {
            if (old) {
                const current = deviceList.find(
                    (device) => device.serial === old.serial,
                );
                if (current) {
                    return current;
                }
            }

            return deviceList.length ? deviceList[0] : undefined;
        });
    }, [deviceList]);
    return (
        <Stack tokens={{ childrenGap: 8, padding: "0 0 8px 8px" }}>
            <Dialog
                hidden={!connecting}
                dialogContentProps={{
                    title: "Đang kết nối...",
                    subText: "Vui lòng xác thực kết nối trên thiết bị của bạn",
                }}
            >
                <ProgressIndicator />
            </Dialog>
        </Stack>
    );
}

export const Connect = observer(ConnectCore);